
import { computed, defineComponent, unref } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { activityLogo, formatDate } from "@/graphql/utils/utils";
import { SequenceRapportData } from "@/graphql/sequence/sequence-closure-rapport";
import { PaymentTypeEnum } from "@/graphql/ticket/ticket";
import { Sequence } from "@/graphql/types";

export default defineComponent({
  name: "SequenceTicketRapport",
  props: ["sequence", "result"],
  setup(props: { sequence: Sequence; result: SequenceRapportData }) {
    function sumProducts(lines: any[]) {
      return lines.reduce((sum, cur) => sum + cur.inclTax, 0);
    }

    function getResume(data: SequenceRapportData) {
      return {
        ...data.sequencePaymentsRapport,
        sold:
          (data.sequencePaymentsRapport.paymentsByTypes.find(
            (type) => type.type === PaymentTypeEnum.ESPECE
          )?.amount || 0) - rapport.value.sequenceExpenseRapport,
        creditsTotal: data.sequenceCreditsRapport.reduce(
          (cum, cr) => cum + cr.amount - cr.discount,
          0
        ),
        paymentsByTypes: data.sequencePaymentsRapport.paymentsByTypes.filter(
          ({ type }) =>
            [
              PaymentTypeEnum.OFFERT,
              PaymentTypeEnum.VIREMENT,
              PaymentTypeEnum.CHEQUE,
              PaymentTypeEnum.AUTRE,
            ].includes(type)
        ),
        product: sumProducts(data.sequenceProductsRapport),
        category: sumProducts(data.sequenceCategoriesRapport),
        back: sumProducts(data.sequenceTicketsBackRapport),
      };
    }

    const rapport = computed(() => {
      if (props.result) {
        return props.result;
      }
      return {
        sequenceCreditsRapport: [],
        sequenceCategoriesRapport: [],
        sequenceProductsRapport: [],
        sequencePaymentsRapport: {
          total: 0,
          payed: 0,
          discount: 0,
          credits: 0,
          creditPayments: 0,
          offer: 0,
          paymentsByTypes: [],
        },
        sequenceExpenseRapport: 0,
        sequenceTicketsBackRapport: [],
        productDiscount: 0, // sum of all discounts lines
      };
    });

    return {
      activeActivity,
      activityLogo,
      rapport,
      formatDate,
      resume: computed(() => getResume(unref(rapport.value))),
      seq: computed(() => props.sequence || props.result?.sequenceGet),
      creditRemain: (credit: any) => {
        return credit.amount - credit.discount - credit.received;
      },
    };
  },
});
